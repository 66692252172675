import * as React from 'react'
import { Container, Grid, Box, Heading } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import Link from '../../components/link'
import FlatBorder from '../../components/funky-border'

const Collection = ({
  data: { allShopifyCollection },
  pageContext: { breadcrumb },
}) => {
  return (
    <Layout breadcrumbs={breadcrumb}>
      <Seo
        title="All Collections | Mud Foods"
        description="Browse our delicious range of pies, squiches, gourmet meals and sides. All our products are handcrafted using family recipes and traditional methods. Shop now"
      ></Seo>

      <Container py={5}>
        <Heading as="h1" textStyle="h1">
          Collections
        </Heading>
        <Grid
          templateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(2, 1fr)']}
          columnGap={[2, 3, 4]}
          rowGap={[12, 16, 20]}
        >
          {allShopifyCollection.nodes.map(
            (collection) =>
              (collection.metafield == null ||
                collection.metafield.staging == 'true') &&
              collection.title &&
              collection.image && (
                <Box key={`collection.handle -col`}>
                  <Heading as="h2" size="lg">
                    {collection.title}
                  </Heading>
                  <Link to={`/collections/${collection.handle}`}>
                    <FlatBorder tags={[collection.handle]}>
                      <GatsbyImage
                        alt=""
                        image={
                          collection.image.localFile.childImageSharp
                            .gatsbyImageData
                        }
                      />
                    </FlatBorder>
                  </Link>
                </Box>
              )
          )}
        </Grid>
      </Container>
    </Layout>
  )
}

export default Collection

export const query = graphql`
  {
    allShopifyCollection(filter: { handle: { ne: "birthday-giftboxes" } }) {
      nodes {
        description
        title
        metafield(key: "staging", namespace: "custom") {
          value
        }
        handle
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1
                formats: [AUTO, WEBP]
                quality: 40
                width: 560
              )
            }
          }
        }
      }
    }
  }
`
